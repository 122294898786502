var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-page-header',{staticClass:"header_title",attrs:{"title":_vm.title},on:{"back":function () { return _vm.$router.go(-1); }}}),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'Email',
          { rules: [{ required: true, message: 'Please input your Email' }] } ]),expression:"[\n          'Email',\n          { rules: [{ required: true, message: 'Please input your Email' }] },\n        ]"}],attrs:{"placeholder":"Please input your Email"}})],1),_c('a-form-item',{attrs:{"label":"Password"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'Password',
          {
            rules: [
              { required: true, message: 'Please input your Password!' } ],
          } ]),expression:"[\n          'Password',\n          {\n            rules: [\n              { required: true, message: 'Please input your Password!' },\n            ],\n          },\n        ]"}],attrs:{"type":"password","placeholder":"Password"}})],1),_c('a-form-item',{attrs:{"label":"BrandName"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'BrandName',
          {
            rules: [
              { required: true, message: 'Please input your BrandName' } ],
          } ]),expression:"[\n          'BrandName',\n          {\n            rules: [\n              { required: true, message: 'Please input your BrandName' },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Please input your BrandName"}})],1),_c('a-form-item',{attrs:{"label":"Type","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'user_type_id',
          { rules: [{ required: true, message: 'Please select type!' }] } ]),expression:"[\n          'user_type_id',\n          { rules: [{ required: true, message: 'Please select type!' }] },\n        ]"}],attrs:{"placeholder":"Please select type"}},_vm._l((_vm.userTypes),function(item){return _c('a-select-option',{key:item.user_type_id,attrs:{"value":item.user_type_id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"CompanyName"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'CompanyName',
          {
            rules: [
              { required: true, message: 'Please input your CompanyName!' } ],
          } ]),expression:"[\n          'CompanyName',\n          {\n            rules: [\n              { required: true, message: 'Please input your CompanyName!' },\n            ],\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"Contacts"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'Contacts',
          {
            rules: [
              { required: true, message: 'Please input your Contacts!' } ],
          } ]),expression:"[\n          'Contacts',\n          {\n            rules: [\n              { required: true, message: 'Please input your Contacts!' },\n            ],\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"ContactsNumber"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'ContactsNumber',
          {
            rules: [
              {
                required: true,
                message: 'Please input your ContactsNumber!',
              } ],
          } ]),expression:"[\n          'ContactsNumber',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Please input your ContactsNumber!',\n              },\n            ],\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"CompanyAddress"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'CompanyAddress',
          {
            rules: [
              {
                required: true,
                message: 'Please input your CompanyAddress!',
              } ],
          } ]),expression:"[\n          'CompanyAddress',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Please input your CompanyAddress!',\n              },\n            ],\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"Website"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'Website',
          {
            rules: [
              { required: true, message: 'Please input your Website!' } ],
          } ]),expression:"[\n          'Website',\n          {\n            rules: [\n              { required: true, message: 'Please input your Website!' },\n            ],\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"Image"}},[_c('Uploadimg',{on:{"getImgUrl":_vm.getImgUrl}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" Submit ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }