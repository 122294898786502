<template>
  <div>
    <a-page-header
      class="header_title"
      :title="title"
      @back="() => $router.go(-1)"
    />
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
      <a-form-item label="Email">
        <a-input
          v-decorator="[
            'Email',
            { rules: [{ required: true, message: 'Please input your Email' }] },
          ]"
          placeholder="Please input your Email"
        />
      </a-form-item>

      <a-form-item label="Password">
        <a-input
          v-decorator="[
            'Password',
            {
              rules: [
                { required: true, message: 'Please input your Password!' },
              ],
            },
          ]"
          type="password"
          placeholder="Password"
        />
      </a-form-item>

      <a-form-item label="BrandName">
        <a-input
          v-decorator="[
            'BrandName',
            {
              rules: [
                { required: true, message: 'Please input your BrandName' },
              ],
            },
          ]"
          placeholder="Please input your BrandName"
        />
      </a-form-item>

      <a-form-item label="Type" has-feedback>
        <a-select
          v-decorator="[
            'user_type_id',
            { rules: [{ required: true, message: 'Please select type!' }] },
          ]"
          placeholder="Please select type"
        >
          <a-select-option
            v-for="item in userTypes"
            :key="item.user_type_id"
            :value="item.user_type_id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="CompanyName">
        <a-input
          v-decorator="[
            'CompanyName',
            {
              rules: [
                { required: true, message: 'Please input your CompanyName!' },
              ],
            },
          ]"
        />
      </a-form-item>

      <a-form-item label="Contacts">
        <a-input
          v-decorator="[
            'Contacts',
            {
              rules: [
                { required: true, message: 'Please input your Contacts!' },
              ],
            },
          ]"
        />
      </a-form-item>

      <a-form-item label="ContactsNumber">
        <a-input
          v-decorator="[
            'ContactsNumber',
            {
              rules: [
                {
                  required: true,
                  message: 'Please input your ContactsNumber!',
                },
              ],
            },
          ]"
        />
      </a-form-item>

      <a-form-item label="CompanyAddress">
        <a-input
          v-decorator="[
            'CompanyAddress',
            {
              rules: [
                {
                  required: true,
                  message: 'Please input your CompanyAddress!',
                },
              ],
            },
          ]"
        />
      </a-form-item>

      <a-form-item label="Website">
        <a-input
          v-decorator="[
            'Website',
            {
              rules: [
                { required: true, message: 'Please input your Website!' },
              ],
            },
          ]"
        />
      </a-form-item>

      <a-form-item label="Image">
        <Uploadimg @getImgUrl="getImgUrl" />
      </a-form-item>

      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
        <a-button type="primary" @click="handleSubmit"> Submit </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import {
  addUser,
  editUser,
  getUserTypes,
  getUserDetail,
} from "@/network/userRequest";
import Uploadimg from "@/components/content/UploadImgRegister";
const userTypes = [
  { user_type_id: 1, name: "模式1" },
  { user_type_id: 2, name: "模式2" },
];

export default {
  name: "NodeprojectEdituser",
  components: { Uploadimg },
  directives: {},
  data() {
    return {
      title: "add",
      imageUrl: "",
      userTypes,
      form: this.$form.createForm(this, { name: "user_from" }),
    };
  },
  created() {
    if (this.$route.query.user_id) {
      this.title = "edit";
      getUserDetail(this.$route.query.user_id).then((res) => {
        if (res.header.code == 200) {
          let {
            Email,
            BrandName,
            user_type_id,
            CompanyName,
            Contacts,
            ContactsNumber,
            CompanyAddress,
            Website,
            img,
          } = res.body.user;
          this.form.setFieldsValue({
            Email,
            BrandName,
            user_type_id,
            CompanyName,
            Contacts,
            ContactsNumber,
            CompanyAddress,
            Website,
          });
          this.imageUrl = img;
        }
      });
    }
  },
  methods: {
    //上传后获取的url地址
    getImgUrl(e) {
      this.imageUrl = e;
    },
    //submit
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.info("success");
          console.log(values);
          let obj = values;
          obj.img = this.imageUrl;
          if (this.$route.query.user_id) {
            obj.user_id = this.$route.query.user_id;
            editUser(obj).then((res) => {
              if (res.header.code == 200) {
                this.$message.success("success!");
                this.$router.back(-1);
                return;
              }
              this.$message.success(res.header.msg);
            });
          } else {
            addUser(obj).then((res) => {
              console.log(res);
              if (res.header.code == 200) {
                this.$message.success("success!");
                this.$router.back(-1);
                return;
              }
              this.$message.success(res.header.msg);
            });
          }
        }
        return false;
      });
    },
  },
};
</script>

<style scoped>
</style>