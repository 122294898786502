<template>
    <div>
           <a-upload
                name="file"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :data="{maker_id:'register'}"
                action="https://shady.ecomgear.cn/kenneth_testing/maker/img/uploadPic.php"
                :before-upload="beforeUpload"
                @change="handleChange"
            >
                <img class="uploadimg2" v-if="imageUrl" :src="imageUrl" alt="avatar" />
                <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                    Upload
                </div>
                </div>
            </a-upload>  
    </div>
</template>

<script>

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
    props:{
      url:{type:String,default:''},
    },
   data() {
    return {
      loading: false,
      imageUrl:this.$props.url,
      width:0,
      height:0
    };
  },
  watch:{
    url(){
      this.imageUrl=this.$props.url
    }
  },
  methods: {
    handleChange(info) {
      // console.log(info)
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (info.file.status === 'error') {
        // Get this url from response in real world.
          this.imageUrl = "error";
          this.loading = false;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl;
        });
        if(info.file.response.header.code==200){
           this.imageUrl = info.file.response.body.url;
           this.$emit('getImgUrl',info.file.response.body.url);
        }
        this.loading = false;

      }
    },
    beforeUpload(file) {
      var that = this;
      const isJPG = file.type === 'image/jpg';
      const isJPEG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const bigLt2M = file.size / 1024 / 1024 < 2;
      return new Promise((resolve, reject) => {
        if (!(isJPG || isJPEG || isPNG)) {
          that.$message.error('You can only upload JPG file!');
          reject(file);
        }else if (!bigLt2M) {
          that.$message.error('Image must smaller than 2MB!');
          reject(file);
        }else {
          resolve(file && this.checkImageWH2(file, 300, 300));
        }
      });
    },
    checkImageWH2(file, width, height) {
      var that = this;
      return new Promise(function(resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = e => {
          let src = e.target.result;
          const image = new Image();
          image.onload = function() {
            // 获取图片的宽高，并存放到file对象中
            console.log('file width :' + this.width);
            console.log('file height :' + this.height);
            file.width = this.width;
            file.height = this.height;
            if (this.width == width && this.height == height)
            {
              resolve();
            } else {
              that.$message.error('Please upload '+width+'px*'+height+'px image');
              reject();
            }
          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });
    }
  },
};
</script>

<style  scoped>
  /* .uploadimg2{max-height: 100%;max-width: 100%;} */
  .uploadimg2{max-height: 86px;max-width: 100%;}
</style>